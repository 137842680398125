@media print {
    body {
        font-size: 10.5px !important;
        line-height: 16px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        margin: 0;
    }

    h3 {
        display: none;
    }

    footer {
        display: none;
    }

    .logo {
        float: right;
        padding-right: 20px;
        width: 200px;
    }

    .logo img {
        width: 100%;
        height: auto;
        display: block;
    }

    a[href]:after {
        content: none !important;
    }

    .navbar-brand, .main-nav, .call-block, .navbar-header, .print-btn, .page-table {
        display: none;
    }

    h1, h2 {
        display: none;
    }

    .row info {
        display: block;
    }

    .onlyprint {
        display: block;
    }

    .right {
        text-align: right;
    }

    .padding {
        /*padding-left: 15px;
        padding-right: 15px;*/
    }

    .indent {
        margin-top: 1em;
        padding-top: 1em;
    }

    .inter {
        margin-top: 1em;
        padding-top: 1em;
    }

    #underline {
        text-decoration: underline;
        font-size: 8px !important;
        white-space: nowrap;
    }

    #signature_chmm {
        width: 6cm !important;
    }

    #signature_pb {
        width: 50%;
        height: auto;
        margin: 0 0 0 -15px;
    }

    #signature_sb {
        width: 3.5cm;
    }

    #signature_paulb {
        width: 3.5cm;
    }

    #borrower {
        width: 45%;
        margin-left: 100px;
        height: auto;
    }

    .font-size {
        font-size: 14.5px !important;
    }

    .font-italic {
        font-style: italic;
    }

    .tax-table-bordered th {
        text-align: left;
    }

    .tax-table-bordered > tbody > tr > td,
    .tax-table-bordered > tbody > tr > th,
    .tax-table-bordered > tfoot > tr > td,
    .tax-table-bordered > tfoot > tr > th,
    .tax-table-bordered > thead > tr > td,
    .tax-table-bordered > thead > tr > th {
        border: 1px solid #3C3C3C !important;
        padding: 5px;
        vertical-align: top;
    }

    :after, :before {
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
    }

    * {
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
    }

    .row {
        margin: 0;
    }

    .col-xs-6 {
        width: 50%;
        float: left;
        padding: 0;
    }

    .col-sm-12 {
        padding: 0;
    }
}

@media print {
    img {
        vertical-align: top;
        margin: 0 0 16px;
    }

    body > .container {
        padding-top: 0 !important;
        margin-top: 0;
    }

    .header-top {
        display: none;
    }

    .tax-holder {
        padding: 0 15px;
    }

    .tax-holder p {
        padding: 0 0 10px !important;
    }

    table {
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
        font-size: 11px !important;
        line-height: 16px;
    }

    thead {
        display: table-header-group;
        page-break-inside: avoid;
    }

    tfoot {
        font-weight: bold;
    }

    tbody {
        display: table-row-group;
    }

    table tr {
        page-break-inside: avoid;
    }

    .page-break-inside {
        page-break-inside: avoid;
        padding-bottom: 30px;
    }

    table tbody td {
        white-space: nowrap;
    }

    table td,
    table th {
        line-height: 1.42857143;
    }

    table .text-right {
        text-align: right;
    }

    .tax-table-bordered .first-child {
        width: 17%;
    }

    .table .paid-service-col {
        width: 14%;
    }

    .table .from-col,
    .table to-col {
        width: 13%;
    }

    .table .nominal-col {
        width: 11%;
    }

    .table .capital-col {
        width: 18%;
    }

    .text-info {
        margin: 0 0 20px;
        font-size: 14.5px;
    }

    @page {
        margin-left: 80px;
        margin-right: 80px;
        @bottom-right {
            content: counter(page) " of " counter(pages);
        }
    }

    .signature-images {
        height: 2cm;
    }

    .signature-images .col-xs-6 {
        position: relative;
    }

    .signature-images img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 3.5cm !important;
        max-width: 100% !important;
    }
}
