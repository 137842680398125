@media print {
    body {
        font-size: 13px;
        line-height: 16px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 400;
    }

    p {
        margin: 0;
        font-size: 13px;
        line-height: 16px;
    }

    .navbar.navbar-inverse {
        display: none;
    }

    h2 {
        color: #3c3c3c;
        font-size: 18px;
        margin: 0 0 20px;
    }

    .credit-values {
        border: 1px solid #3c3c3c;
        padding: 10px 20px 10px;
        margin: 0 0 15px;
        background: #f8f8f8;
        overflow: hidden;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-2 {
        width: 16%;
    }

    .credit-values .row {
        overflow: hidden;
    }

    .row:after,
    .border-bottom-block:after {
        content: " ";
        display: table;
        clear: both;
    }

    .credit-values .row + .row {
        padding-top: 10px;
    }

    h3 {
        font-size: 16px;
        font-weight: 300;
    }

    h5 {
        font-size: 14px;
        font-weight: 300;
    }

    .overview-block label {
        font-weight: bold;
        margin: 0;
        padding: 0 5px 0 0;
        width: 145px !important;
    }

    .overview-block .row {
        overflow: hidden;
    }

    .form-group {
        padding-bottom: 0px;
        margin-bottom: 0;
        margin-top: 0;
        padding-top: 0;
        overflow: hidden;
    }

    .overview-block p,
    .overview-block label,
    .col-sm-6,
    .overview-block .row {
        margin-bottom: 0;
        padding-bottom: 0;
        margin-top: 0;
        padding-top: 0;
        word-break: break-all;
    }

    .border-bottom-block {
        padding: 0 0 20px;
        margin: 0 0 25px;
        border-bottom: 1px solid #3c3c3c;
        overflow: hidden;
    }

    .body > .container {
        margin: 0 !important;
    }

    label {
        font-weight: bold;
    }

    .row.submitdiv {
        overflow: hidden;
        padding-bottom: 10px;
    }

    .categories-list {
        display: none;
    }

    .categories-list li {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        text-align: center;
    }

    .col-sm-12 {
        width: 100%;
        /*width: calc(100% - 30px);
        padding-right: 15px;
        padding-left: 15px;*/
    }

    .col-xs-6 {
        width: 50%;
        float: left;
    }

    .row > .col-md-12.col-md-offset-3 > .col-sm-5 {
        border: 0 !important;
        padding: 0 !important;
    }

    /*
    Modal
    */
    body.modal-open > .main,
    .modal-footer,
    .modal-backdrop, .nachrichten {
        display: none;
    }

    .modal {
        display: none;
    }

    .modal-dialog {
        width: auto;
    }

    .modal-content,
    .modal-header,
    .modal-body {
        border: none;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }

    body.modal-open .modal.in {
        text-align: right;
    }

    body.modal-open .modal.in .modal-dialog {
        text-align: left;
    }

    body.modal-open .modal.in:before {
        content: url(https://www.creditgate24.com/wp-content/uploads/2016/04/143x65.png);
    }

    body.modal-open footer {
        display: block !important;
        border-top: 1px solid black;
        margin-top: 1cm;
        padding-top: 1cm;
    }

    body.modal-open footer * {
        display: none;
    }

    body.modal-open footer > .container,
    body.modal-open footer > .container > .footer-contact,
    body.modal-open footer > .container > .footer-contact * {
        display: block;

    }

    body.modal-open footer > .container {
        padding: 0;
        margin: 0;
    }

    .print-section {
        page-break-inside: avoid;
    }
}
