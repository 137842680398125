@media print {
    body {
        font-size: 13px;
        line-height: 16px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    p {
        margin: 0;
        font-size: 13px;
        line-height: 16px;
    }

    header, footer,
    #acceptAGB_field,
    .row.submit,
    .navbar.navbar-inverse {
        display: none !important;
    }

    .main {
        background: #fff;
    }

    h1 {
        color: #3c3c3c;
        font-size: 18px;
        margin: 0 0 20px;
    }

    .main .content-holder {
        margin: 0 0 20px;
        padding: 0;
    }

    .credit-values {
        border: 1px solid #3c3c3c;
        padding: 20px 20px 10px;
        margin: 0 0 15px;
        background: #f8f8f8;
    }

    .credit-values .credit-values-title {
        margin: 0 0 10px;
    }

    .col-md-2, .col-md-3, .col-sm-6 {
        float: left;
        box-sizing: border-box;
    }

    .col-sm-6 {
        width: 50%;
    }

    h3 {
        font-size: 16px;
        font-weight: bold;
    }

    h3.bordered {
        padding: 0 0 20px;
        border-bottom: 1px solid #3c3c3c;
    }

    .form-group.static {
        margin: 0 0 10px;
    }

    .form-holder.overview-form .col-sm-6 label {
        width: 170px;
    }

    .form-group.static label {
        margin: 0;
    }

    .col-sm-6 label {
        padding: 5px 10px 0 0;
        width: 150px;
        float: left;
    }

    label {
        font-size: 13px;
        line-height: 16px;
    }

    .form-group.static p.form-control-static {
        overflow: hidden;
    }

    .border-bottom-block {
        padding: 0 0 20px;
        margin: 0 0 25px;
        border-bottom: 1px solid #3c3c3c;
    }

    /*
    Modal
    */
    body.modal-open > .main,
    .modal-footer,
    .modal-backdrop {
        display: none;
    }

    .modal {
        display: block;
        position: static;
    }

    .modal p {
        margin-bottom: .75em;
        font-size: inherit;
        line-height: inherit;
    }

    .modal-header,
    .modal-header h4 {
        padding: 0;
        margin: 0;
    }

    .modal-header h4 {
        font-size: 120%;
        font-weight: bold;
    }

    .modal-body {
        margin-top: 2em;
    }

    .modal-dialog {
        width: auto;
        margin: 1cm 0 0 0;
        padding: 0;
    }

    .modal-content,
    .modal-header,
    .modal-body {
        border: none;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }

    body.modal-open > .modal {
        text-align: right;
    }

    body.modal-open > .modal > .modal-dialog {
        text-align: left;
    }

    body.modal-open > .modal:before {
        content: url(https://www.creditgate24.com/wp-content/uploads/2016/04/143x65.png);
    }

    body.modal-open footer {
        display: block !important;
        border-top: 1px solid black;
        margin-top: 1em;
        padding-top: 1em;
    }

    body.modal-open footer * {
        display: none;
    }

    body.modal-open footer > .container > .footer-contact > .title {
        display: none;
    }

    body.modal-open footer > .container,
    body.modal-open footer > .container > .footer-contact,
    body.modal-open footer > .container > .footer-contact * {
        display: block;

    }

    body.modal-open footer > .container {
        padding: 0;
        margin: 0;
    }

    blockquote {
        font-size: inherit;
        font-family: inherit;
        border: none;
        border-left: #ddd solid .2em;
        padding-left: 2em;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
    }
}